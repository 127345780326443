.empty-page-state {
    padding: 32px;
    text-align: center;
    & > svg {
        margin-bottom: 8px;
    }
    p {
        color: var(--ping-Grey-grey-500);
    }
    &__text {
        margin-bottom: 16px;
    }
}
