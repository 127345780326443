.successful-sidebar {
    .order-summary-row {
        align-items: flex-start;
        span {
            &:first-child {
                white-space: nowrap;
                padding-right: 16px;
            }
            &:last-child {
                word-break: break-all;
                max-width: 65%;
            }
        }
    }
}
