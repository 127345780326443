@import "styles/mixins";
@import "styles/texts";

.custom-slider {
    padding: 32px 16px 0;

    .MuiSlider-rail {
        opacity: 0.15;
    }
    .MuiSlider-markLabel {
        @include body2;
        color: var(--ping-Grey-grey-500);

        &.MuiSlider-markLabelActive {
            color: var(--ping-Grey-grey-800);
        }
    }
    .MuiSlider-valueLabelOpen.MuiSlider-valueLabel {
        border-radius: 4px;
        background: var(--ping-Brand-brand-500);
        padding: 4px 8px;
        @include subtitle1;
    }
    .MuiSlider-root.MuiSlider-marked {
        height: 8px;
        color: var(--ping-Brand-brand-400);
    }
    .MuiSlider-mark {
        display: none;
    }
    .MuiSlider-thumb {
        border: 2px solid var(--ping-Brand-brand-500);
        border-radius: 3px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'%3E%3Crect width='22' height='22' rx='3' fill='white'/%3E%3Cpath d='M9 7.5C9 8.32843 8.32843 9 7.5 9C6.67157 9 6 8.32843 6 7.5C6 6.67157 6.67157 6 7.5 6C8.32843 6 9 6.67157 9 7.5Z' fill='%23D2D6DB'/%3E%3Cpath d='M16 7.5C16 8.32843 15.3284 9 14.5 9C13.6716 9 13 8.32843 13 7.5C13 6.67157 13.6716 6 14.5 6C15.3284 6 16 6.67157 16 7.5Z' fill='%23D2D6DB'/%3E%3Cpath d='M16 14.5C16 15.3284 15.3284 16 14.5 16C13.6716 16 13 15.3284 13 14.5C13 13.6716 13.6716 13 14.5 13C15.3284 13 16 13.6716 16 14.5Z' fill='%23D2D6DB'/%3E%3Cpath d='M9 14.5C9 15.3284 8.32843 16 7.5 16C6.67157 16 6 15.3284 6 14.5C6 13.6716 6.67157 13 7.5 13C8.32843 13 9 13.6716 9 14.5Z' fill='%23D2D6DB'/%3E%3C/svg%3E");
    }
    &.reconfigure-slider {
        padding: 24px 0;
        .reconfigure-slider__inner {
            padding: 0 10px;
            .MuiSlider-markLabel {
                transform: none;
                &[data-index="0"] {
                    margin-left: -10px;
                }
                &[data-index="1"] {
                    margin-right: 0;
                    left: auto !important;
                    right: 0;
                    margin-right: -10px;
                }
            }
        }
    }
}
