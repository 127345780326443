@import "styles/texts.scss";

.tooltip-component {
    display: flex;
    cursor: pointer;
    color: #9da4ae;
    transition: color 0.3s;
    padding-bottom: 2px;
    span {
        display: flex;
    }
    &:hover {
        color: #000;
    }
    // tooltip styles in main.scss;
    .MuiTooltip-tooltip.MuiTooltip-tooltipArrow {
        margin-bottom: 10px;
    }
}
