@import 'styles/mixins';

.custom-coutry-dropdown {
    margin-top: 24px;

    &__option {
    }
    &__status {
        color: var(--ping-Grey-grey-500);
    }
    .custom-coutry-dropdown-container {
        position: relative;
        &.has-value {
            input {
                padding-left: 25px;
            }
        }
        .country-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 8px;
        }

        .tooltip {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 60px;
            z-index: 1;
        }

        .product-stock {
            position: absolute;
            right: 60px;
            top: 50%;
            transform: translateY(-50%);
            color: var(--ping-Grey-grey-500);
            font-weight: 500;
            @include w($xs) {
                span:last-child {
                    display: none;
                }
            }
        }
    }
    &.billing-country-dropwdown {
        margin-top: 0;
        input {
            &::placeholder {
                color: var(--ping-Grey-grey-500);
                opacity: 1;
            }
        }
        .MuiInputBase-root.MuiOutlinedInput-root {
            padding-top: 1px;
            padding-bottom: 1px;
        }
    }
}

.MuiPopper-root.MuiAutocomplete-popper > div {
    // max-height: 185px;
}
