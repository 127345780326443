@import 'styles/reset';
@import 'styles/mixins';
@import 'styles/variables';
@import 'styles/texts';
@import 'styles/fonts';

:root {
    --main: #1f2a37;

    --ping-Grey-grey-50: #f9fafb;
    --ping-Grey-grey-100: #f3f4f6;
    --ping-Grey-grey-200: #e5e7eb;
    --ping-Grey-grey-300: #d2d6db;
    --ping-Grey-grey-400: #9da4ae;
    --ping-Grey-grey-500: #6c737f;
    --ping-Grey-grey-600: #4d5761;
    --ping-Grey-grey-700: #384250;
    --ping-Grey-grey-800: #1f2a37;
    --ping-Grey-grey-900: #111927;

    --ping-Brand-brand-50: #eeedfd;
    --ping-Brand-brand-100: #cac6f9;
    --ping-Brand-brand-200: #b1aaf6;
    --ping-Brand-brand-300: #8d84f2;
    --ping-Brand-brand-400: #776cef;
    --ping-Brand-brand-500: #5547eb;
    --ping-Brand-brand-600: #4d41d6;
    --ping-Brand-brand-700: #3c32a7;
    --ping-Brand-brand-800: #2f2781;
    --ping-Brand-brand-900: #241e63;

    --ping-Blue-blue-50: #e9f2fc;
    --ping-Blue-blue-100: #bad6f5;
    --ping-Blue-blue-200: #99c2f1;
    --ping-Blue-blue-300: #6ba7ea;
    --ping-Blue-blue-400: #4e95e6;
    --ping-Blue-blue-500: #2d81e1;
    --ping-Blue-blue-600: #1f70cc;
    --ping-Blue-blue-700: #18579f;
    --ping-Blue-blue-800: #13447b;
    --ping-Blue-blue-900: #0e345e;

    --ping-Purple-purple-50: #faefff;
    --ping-Purple-purple-300: #e095ff;

    --ping-Green-green-50: #e9f9ef;
    --ping-Green-green-100: #baedcd;
    --ping-Green-green-200: #99e4b5;
    --ping-Green-green-300: #6bd893;
    --ping-Green-green-400: #4ed17e;
    --ping-Green-green-500: #22c55e;
    --ping-Green-green-600: #1fb356;
    --ping-Green-green-700: #188c43;
    --ping-Green-green-800: #136c34;
    --ping-Green-green-900: #0e5327;

    --ping-Red-red-50: #fdebea;
    --ping-Red-red-100: #f9c2be;
    --ping-Red-red-200: #f6a49e;
    --ping-Red-red-300: #f27a72;
    --ping-Red-red-400: #ef6157;
    --ping-Red-red-500: #eb392d;
    --ping-Red-red-600: #d63429;
    --ping-Red-red-700: #a72820;
    --ping-Red-red-800: #811f19;
    --ping-Red-red-900: #631813;

    --ping-Orange-orange-50: #fef7eb;
    --ping-Orange-orange-100: #fbe6c1;
    --ping-Orange-orange-200: #f9d9a3;
    --ping-Orange-orange-300: #f6c878;
    --ping-Orange-orange-400: #f5bd5e;
    --ping-Orange-orange-500: #f2ad36;
    --ping-Orange-orange-600: #dc9d31;
    --ping-Orange-orange-700: #ac7b26;
    --ping-Orange-orange-800: #855f1e;
    --ping-Orange-orange-900: #664917;
}

html {
    -webkit-text-size-adjust: 100%;
}

body {
    font-family: 'Space Grotesk', sans-serif;
    color: var(--main);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.main-layout {
    height: 100vh;
    display: grid;
    background-color: #fafafa;
    grid-template-columns: 280px 1fr;
    aside {
    }
    main {
        height: 100%;
        overflow-y: auto;
        padding: 24px 48px;
    }
    @include w($xxl) {
        main {
            padding: 24px;
        }
    }
    @include w($lg) {
        height: auto;
        min-height: 100dvh;
        display: block;
        aside {
            position: sticky;
            top: 0;
            background-color: #fff;
            z-index: 10;
        }
        main {
        }
    }
    @include w($md) {
        main {
            padding: 16px;
        }
    }
}

.text {
    color: var(--ping-Grey-grey-500);
    @include body2;
}
.text-grey-500 {
    color: var(--ping-Grey-grey-500);
}
.text-grey-600 {
    color: var(--ping-Grey-grey-600);
}
.text-grey-700 {
    color: var(--ping-Grey-grey-700);
}
.text-brand-500 {
    color: var(--ping-Brand-brand-500);
}
.text-red-500 {
    color: var(--ping-Red-red-500);
}
.pb-4 {
    padding-bottom: 4px !important;
}
.pb-8 {
    padding-bottom: 8px !important;
}
.pb-16 {
    padding-bottom: 16px !important;
}
.pt-16 {
    padding-top: 16px !important;
}
.pb-24 {
    padding-bottom: 24px !important;
}
.pt-24 {
    padding-top: 24px !important;
}
.mb-8 {
    margin-bottom: 8px !important;
}
.mt-8 {
    margin-top: 8px !important;
}
.mt-16 {
    margin-top: 16px !important;
}
.mb-24 {
    margin-bottom: 24px !important;
}
.mt-24 {
    margin-top: 24px !important;
}
.opacity-60 {
    opacity: 0.6;
}
.page-main-title {
    margin-bottom: 24px;
}
.box {
    @include box;
    &-error {
        @include box;
        border: 1px solid var(--ping-Red-red-300);
        background-color: var(--ping-Red-red-50);
        color: var(--ping-Red-red-800);
        font-style: normal;
        font-weight: 500;
        line-height: 1.3;
        font-size: 14px;
    }
}

.md-hide {
    @include w($md) {
        display: none !important;
    }
}
.MuiTooltip-tooltip.MuiTooltip-tooltipArrow {
    @include body2;
    padding: 8px 12px;
    color: #fff;
    background-color: var(--ping-Brand-brand-900);
    white-space: pre-wrap;
}

.header-logo {
    display: inline-flex;
}
.dot {
    display: inline-flex;
    background-color: currentColor;
    border-radius: 50%;
    width: 3px;
    height: 3px;
}

.MuiButtonBase-root.MuiMenuItem-root {
    &.item-danger {
        color: var(--ping-Red-red-800);
    }
}

.MuiListItemIcon-root {
    min-width: auto !important;
    margin-right: 8px;
    svg {
        width: 20px;
        height: 20px;
    }
}
.self-start {
    align-self: flex-start;
}
.self-end {
    align-self: flex-end;
}
.content-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-break-all {
    word-break: break-all;
}

.inputs-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    & > * {
        flex: 0 1 calc(33.33% - 32px / 3);
    }
    @include w($md) {
        & > * {
            flex: 1 1 auto;
        }
    }
}

.account-settings-card {
    @include box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    &__title {
    }
    &__desc {
        margin-top: 8px;
        @include body1;
        color: var(--ping-Grey-grey-700);
    }
}

.account-settings-buttons {
    display: flex;
    gap: 8px;
}

.radio-card-icons {
    display: flex;
    margin-left: auto;
    gap: 8px;
}

.radio-card-icon {
    display: flex;
    margin-left: auto;
}

.notistack-Snackbar {
    min-width: 300px;
}

div#mava {
    z-index: 10 !important;
}

.pointer {
    cursor: pointer;
}
