@import 'styles/mixins';

.billing-layout {
}

.transaction-balance-card {
    @include box;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    &__left {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &__right {
    }
}

.invoice-card {
    display: flex;
    flex-direction: column;
    @include box;
    padding: 16px;
    gap: 4px;
    max-width: 320px;
    @include w($sm) {
        width: 100%;
        max-width: none;
    }
}

.invoces-bar {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.invoices-table-buttons-wrapper {
    display: flex;
    gap: 12px;
}

.subscriptions-filter-bar-wrapper {
    margin-bottom: -24px;
}
