@import "styles/texts";
@import "styles/mixins";

.custom-otp {
    &__inputs {
        display: flex;
        align-items: center;
        gap: 4px;
        .MuiInput-root.Mui-error {
            .MuiInput-input {
                border: 1px solid var(--ping-Red-red-400);
                background-color: var(--ping-Red-red-50);
            }
        }
        .MuiInput-input {
            width: 48px;
            height: 48px;
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            &.custom-otp-input-filled {
                border-color: var(--ping-Brand-brand-400);
                background-color: var(--ping-Brand-brand-50);
            }
        }
    }
    &.custom-otp--disabled .MuiInput-input {
        border-color: var(--ping-Grey-grey-300);
        background-color: var(--ping-Grey-grey-50);
    }
    .MuiFormHelperText-root {
        margin: 0;
        @include body2;
        color: var(--ping-Red-red-500);
    }
    &.custom-otp--big {
        .MuiInput-input {
            $size: 45px;
            width: $size;
            height: $size;
        }
        .custom-otp__inputs {
            justify-content: space-between;
        }
        @include w($md) {
            .MuiInput-input {
                $size: 35px;
                width: $size;
                height: $size;
            }
        }
    }
}
