@import "styles/texts";

.invoice-info-box {
    h4 {
        margin-bottom: 24px;
    }
    .invoice-list {
        @include body2;
        color: var(--ping-Grey-grey-700);
        border: 1px solid var(--ping-Grey-grey-200);
        border-radius: 4px;
        &__item {
            padding: 10px 16px;
            & + .invoice-list__item {
                border-top: 1px solid var(--ping-Grey-grey-200);
            }
        }
    }
}