@import "styles/texts";

.service-line {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
    &__icon {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        svg {
            font-size: 36px;
            width: 1em;
            height: 1em;
        }
    }
    &__content {
    }
    &-title {
        @include subtitle2;
        color: var(--ping-Grey-grey-800);
    }
    &-info {
        display: flex;
        gap: 6px;
        align-items: center;
        color: var(--ping-Grey-grey-500);
        @include caption;
    }
}
