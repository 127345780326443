@import 'styles/texts';

.reconfigure-popup-final {
    hr {
        background-color: var(--ping-Grey-grey-300);
        margin-top: 24px;
        margin-bottom: 16px;
        height: 1px;
    }
    p {
        @include text-label;
        letter-spacing: -0.2px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & + p {
            padding-top: 8px;
        }
        span {
            &:first-child {
                color: var(--ping-Grey-grey-600);
            }
            &:last-child {
                color: var(--ping-Grey-grey-800);
            }
        }
    }
}

.description {
    @include subtitle2;
    color: var(--ping-Grey-grey-800);
}
