@import "styles/mixins";

.with-sidebar-layout {
    display: flex;
    gap: 24px;
    &__main {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: calc(100% - 24px - 360px);
    }
    &__aside {
        flex: 1 0 360px;
    }
    @include w($xl) {
        flex-direction: column;
        &__main,
        &__aside {
            width: 100%;
        }
    }
}
