@import "styles/texts.scss";

.custom-link {
    display: flex;
    gap: 4px;
    color: var(--ping-Brand-brand-500);
    @include subtitle2;
    transition: 0.3s;
    .custom-link__icon {
        transition: inherit;
        color: var(--ping-Brand-brand-300);
    }
    &:hover {
        color: var(--ping-Brand-brand-900);
        .custom-link__icon {
            color: var(--ping-Brand-brand-900);
        }
    }
}
