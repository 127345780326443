.dashboard-sidebar {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    &-item {
        border-radius: 8px;
        border: 1px solid var(--ping-Grey-grey-200);
        background-color: #fff;
        padding: 12px;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    & > div {
        h6 {
            padding-bottom: 16px;
        }
    }
    .caption {
        color: var(--ping-Grey-grey-600);
        padding-bottom: 12px;
    }
    a + a {
        margin-top: 8px;
    }
}
