@import "styles/texts";

.custom-autocomplete {
    .MuiAutocomplete-popper {
        display: none;
    }
    .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
        padding-top: 3px !important;
        padding-bottom: 4px !important;
    }
    input {
        &::placeholder {
            color: var(--ping-Grey-grey-500);
            opacity: 1;
            font-size: 14px;
        }
    }
    .MuiChip-root.MuiChip-filled.MuiChip-sizeSmall {
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        border-color: var(--ping-Grey-grey-300);
        background-color: var(--ping-Grey-grey-50);
        color: var(--ping-Grey-grey-600);
        @include caption;
    }
    .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input {
        min-width: 50%;
    }
}
