.discount-form {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-top: 16px;
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
        height: 28px;
        input {
            &::placeholder {
                font-size: 14px;
            }
        }
    }
}
