.custom-bage {
    &.MuiBadge-root {
        & > .MuiBadge-badge.MuiBadge-standard {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.2px;
            padding: 0 6px;
            border-radius: 20px;
            border: 2px solid #fff;
            background: var(--ping-Brand-brand-400);
        }
    }
}
