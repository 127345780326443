.page-404 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &-content {
        max-width: 400px;
    }
}
