.modal-container {
    width: 100%;
    display: flex;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-8 {
    margin-top: 8px;
}

.scrollable-container {
    margin-top: 20px;
    height: 300px;
    overflow-y: scroll;
    padding: 10px;
    border-radius: 10px;
    flex: 1;
    border: 1px solid var(--ping-Grey-grey-300);
    background-color: var(--ping-Grey-grey-50);
    color: var(--ping-Grey-grey-600);
}

.copy-button-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.spinner-container {
    height: 370px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-container-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}
