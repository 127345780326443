@import "styles/mixins";

.auth-layout {
    height: 100vh;
    background-color: #fafafa;
    display: flex;

    main {
        flex-grow: 1;
        height: 100%;
        padding: 24px;
        display: flex;
        flex-direction: column;
        .auth-header {
            @include w($md) {
                margin-bottom: 32px;
            }
        }
        .auth-form-container {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    @include w($lg) {
        flex-direction: column-reverse;
        height: auto;
        min-height: 100dvh;
        main .auth-form-container {
            align-items: flex-start;
        }
    }

    .auth-banner {
        height: 100%;
        width: 720px;
        padding: 48px;
        background:
            url("../../images/auth-banner-bg.svg") no-repeat top,
            linear-gradient(225deg, #a192ec 0%, #5547eb 100%);
        text-align: center;
        color: #fff;
        display: flex;
        flex-direction: column;
        &__top {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &__bottom {
        }

        h1 {
            margin: 24px 0;
        }
        @include w($lg) {
            width: 100%;
            padding: 32px 16px;
            h1 {
                margin: 16px 0;
            }
        }
        @include w($md) {
            h1,
            p {
                display: none;
            }
        }
    }
}

.trustpilot-label {
    color: #0d1126;
    display: inline-flex;
    padding: 8px 16px;
    align-items: center;
    gap: 12px;
    border-radius: 100px;
    background-color: #fff;
    margin: 0 auto;
}

.partners {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-top: 24px;
    &-item {
        height: 64px;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
