@import "styles/texts";

.message-text {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
    &--good {
        color: var(--ping-Green-green-700);
    }
    &--bad {
        color: var(--ping-Red-red-700);
    }
    &__text {
        @include h6;
    }
    &--small {
        gap: 8px;
        margin-bottom: 5px;
        .message-text__text {
            font-size: 14px;
        }
        svg {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
        }
    }
}
