@import "styles/mixins";

.page-header {
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;

    &__title {
    }
    &__right {
    }
    @include w($md) {
        padding-bottom: 16px;
    }
}
