@import 'styles/mixins';

.configurator-tab {
    display: flex;
    gap: 24px;
    align-items: stretch;
    @include w($md) {
        flex-direction: column;
        & > div {
            width: 100%;
        }
    }
}

.generate-proxy-form {
    @include box;
    flex-shrink: 0;
    max-width: 100%;
    width: 510px;
    min-height: 640px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: flex-start;
    @include w($lg) {
        width: calc(45% - 24px / 2);
    }
}

.terminal-proxies {
    width: calc(100% - 360px - 24px);
    border-radius: 8px;
    background: var(--ping-Grey-grey-800);
    display: flex;
    flex-direction: column;
    @include w($lg) {
        width: calc(55% - 24px / 2);
    }
    &__screen {
        color: #fff;
        padding: 24px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 2;
        overflow-y: auto;
        flex: 1 1 0.1px;
        p {
            text-overflow: ellipsis;
            overflow: hidden;
        }
        @include w($md) {
            min-height: 300px;
        }
    }
    &__footer {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }
}
