.radio-group-cards {
    width: 100%;
    .MuiFormGroup-root {
        gap: 8px;
    }
    .special-radio-label {
        display: flex;
        align-items: center;
        &__label {
            margin-right: 8px;
        }
        &__additional {
            display: flex;
            align-items: center;
            &.start {
                flex-grow: 1;
            }
            &.end {
                flex-grow: 0;
                margin-left: auto;
            }
        }
    }
    .radio-card {
        position: relative;
        transition: 0.2s;
        border-radius: 4px;
        border: 1px solid var(--ping-Grey-grey-300);

        .MuiFormControlLabel-label {
            flex-grow: 1;
        }
        .MuiFormControlLabel-root.custom-radio {
            width: 100%;
            margin: 0;
            height: 56px;
            padding: 0 12px 0 6px;
            border: 1px solid transparent;
            border-radius: 2px;
        }
        &.checked {
            border: 1px solid var(--ping-Brand-brand-500);
            background: var(--ping-Brand-brand-50);
            .MuiFormControlLabel-root.custom-radio {
                border-color: var(--ping-Brand-brand-500);
            }
        }
    }

    .radio-card-small {
        position: relative;
        transition: 0.2s;
        border-radius: 4px;
        border: 1px solid var(--ping-Grey-grey-300);

        .MuiFormControlLabel-label {
            flex-grow: 1;
        }
        .MuiFormControlLabel-root.custom-radio {
            width: 100%;
            margin: 0;
            height: 35px;
            padding: 0 12px 0 6px;
            border: 1px solid transparent;
            border-radius: 2px;
        }
        &.checked {
            border: 1px solid var(--ping-Brand-brand-500);
            background: var(--ping-Brand-brand-50);
            .MuiFormControlLabel-root.custom-radio {
                border-color: var(--ping-Brand-brand-500);
            }
        }
    }
}

.horizontal {
    .MuiFormGroup-root {
        flex-direction: row;
    }
    .radio-card {
        flex: 1;
    }
}
