@import 'styles/texts.scss';

.custom-select {
    .MuiInputBase-root.Mui-focused.MuiOutlinedInput-root fieldset {
        border-color: var(--ping-Brand-brand-400);
    }
    &__label {
        display: flex;
        align-items: center;
        gap: 0 14px;
    }
    .MuiSelect-icon,
    .MuiSelect-iconOpen {
        top: auto;
    }

    .custom-select-labels-group {
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
        &__placeholder {
            color: var(--ping-Grey-grey-500);
        }
    }
    &.withDisplayOptions {
        .MuiInputBase-root.MuiOutlinedInput-root {
            min-height: 40px;
        }
        .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input {
            min-height: auto;
            padding-top: 8px;
            padding-bottom: 8px;
            max-height: 72px;
            overflow-y: auto;
        }
    }
}

.custom-select__option {
    height: 36px;
    &.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-root {
        padding: 0;
        padding-right: 12px;
        flex-wrap: wrap;
        &.Mui-selected {
            background-color: var(--ping-Brand-brand-50);
        }
    }
    .label-component {
        margin-left: 8px;
    }
    .custom-checkbox {
        padding-top: 0;
        padding-bottom: 0;
        flex-shrink: 0;
    }
    &-name {
        @include body2;
    }
}

.custom-option-with-radio {
    .custom-radio {
        margin-right: 0;
        .MuiRadio-root {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .label-component {
        margin-left: 8px;
    }
}

.select-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
