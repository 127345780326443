@import "styles/texts";

.custom-checkbox {
    &.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
        color: var(--ping-Brand-brand-500);
    }
}
.custom-checkbox-control {
    .MuiTypography-root.MuiFormControlLabel-label {
        @include body1;
    }
}
