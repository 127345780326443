.two-step-authentication-modal {
}
.disable-two-step-authentication {
    display: flex;
    padding: 16px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--ping-Grey-grey-200);
    background-color: var(--ping-Grey-grey-50);
    width: 100%;
    flex-wrap: wrap;
    gap: 16px 0;
    &__message {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--ping-Green-green-800);
        margin-right: auto;
        padding-right: 16px;
    }
}
