@import "./variables";

@mixin w($window-size) {
    @media screen and (max-width: ($window-size - 1px)) {
        @content;
    }
}
@mixin min-w($window-size) {
    @media screen and (min-width: ($window-size)) {
        @content;
    }
}

@mixin min-hover($window-size) {
    @include min-w($window-size) {
        &:hover {
            @content;
        }
    }
}

@mixin hover {
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            @content;
        }
    }
}

@mixin box {
    padding: 24px;
    border-radius: 8px;
    border: 1px solid var(--ping-Grey-grey-200);
    background: #fff;
    @include w($md) {
        padding: 16px;
    }
}
