@import "styles/texts.scss";

.custom-radio {
    .MuiTypography-root.MuiFormControlLabel-label {
        color: var(--ping-Grey-grey-800);
        @include body1;
    }
    .MuiButtonBase-root.MuiRadio-root {
        color: var(--ping-Brand-brand-500);
        flex-shrink: 0;
    }
}
