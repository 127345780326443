@import "styles/mixins";
@import "styles/texts";

.custom-list {
    color: var(--ping-Grey-grey-600);
    column-gap: 24px;
    &[data-columns="1"] {
        columns: 1;
    }
    &[data-columns="2"] {
        columns: 2;
    }
    li {
        @include body2;

        display: flex;
        align-items: flex-start;
        & + li {
            margin-top: 8px;
        }
        svg {
            width: 20px;
            flex-shrink: 0;
            margin-right: 8px;
        }
    }
    @include w($sm) {
        columns: 1 !important;
    }
}
