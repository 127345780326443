.custom-button {
    text-align: center;
    &__loading {
        $size: 0.25em;
        margin-bottom: -7px;
        margin-left: 0.5em;
        position: relative;
        width: $size;
        height: $size;
        border-radius: 5px;
        background-color: currentColor;
        animation: dot-flashing 1s infinite linear alternate;
        animation-delay: 0.5s;

        &::before,
        &::after {
            content: "";
            display: inline-block;
            position: absolute;
            bottom: 0;
            width: $size;
            height: $size;
            border-radius: 5px;
            animation: dot-flashing 1s infinite alternate;
        }
        &::before {
            left: -0.4em;
            background-color: currentColor;
            animation-delay: 0s;
        }
        &::after {
            left: 0.4em;
            background-color: currentColor;
            animation-delay: 1s;
        }

        @keyframes dot-flashing {
            0% {
                background-color: currentColor;
            }
            50%,
            100% {
                background-color: rgba(0, 0, 0, 0.4);
            }
        }
    }
    &.MuiButton-contained {
        box-shadow: none;
    }
    &.color-default {
        &.MuiButton-contained {
            color: #fff;
            background-color: var(--ping-Brand-brand-500);

            &:hover {
                background-color: var(--ping-Brand-brand-700);
            }
            &.Mui-disabled {
                background-color: var(--ping-Grey-grey-200);
                color: var(--ping-Grey-grey-600);
            }
        }

        &.MuiButton-text {
            color: var(--ping-Brand-brand-500);
            &.Mui-disabled {
                color: var(--ping-Grey-grey-600);
            }
        }
        &.MuiButton-outlined {
            border-color: var(--ping-Grey-grey-300);
            &.Mui-disabled {
                color: var(--ping-Grey-grey-600);
                background-color: var(--ping-Grey-grey-200);
            }
            &.bg-white:not(:disabled) {
                background-color: #fff;
            }
        }
    }
    &.color-error {
        &.MuiButton-contained {
            color: #fff;
            background-color: var(--ping-Red-red-500);
            &:hover {
                background-color: var(--ping-Red-red-700);
            }
            &.Mui-disabled {
                background-color: var(--ping-Grey-grey-200);
                color: var(--ping-Grey-grey-600);
            }
        }
        &.MuiButton-outlined {
            border-color: var(--ping-Red-red-300);
            color: var(--ping-Red-red-600);
            &.bg-white {
                background-color: #fff;
            }
        }
    }
    &.color-orange {
        &.MuiButton-contained {
            color: #fff;
            background-color: var(--ping-Orange-orange-500);
            &:hover {
                background-color: var(--ping-Orange-orange-700);
            }
            &.Mui-disabled {
                background-color: var(--ping-Grey-grey-200);
                color: var(--ping-Grey-grey-600);
            }
        }
        &.MuiButton-outlined {
            border-color: var(--ping-Orange-orange-300);
            color: var(--ping-Orange-orange-600);
            &.bg-white {
                background-color: #fff;
            }
        }
    }
    &.button-without-text {
        padding: 0 10px;
        .MuiButton-icon {
            margin: 0;
        }
    }
}
