@import "styles/texts.scss";

.notifications {
    display: flex;
    align-items: center;
}

.notifications-content {
    width: 360px;
    @include w($sm) {
        width: calc(100vw - 24px);
    }
    .MuiButtonBase-root.MuiTab-root {
        padding: 0 24px;
    }
    .custom-tabs {
        .MuiTabs-flexContainer {
            gap: 0;
        }
        .MuiTabPanel-root {
            padding: 0;
        }
    }
    &-header {
        padding: 24px 24px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 60px;
    }
    .mark-as-read-button {
        padding: 0;
    }
}

.messages-list {
    height: 300px;
    overflow-y: auto;
    .message-item {
        position: relative;
        padding: 16px 32px 16px 24px;
        border-bottom: 1px solid var(--ping-Grey-grey-200);
        &__status {
            position: absolute;
            top: 16px;
            right: 16px;
            width: 8px;
            height: 8px;
            background-color: var(--ping-Brand-brand-500);
            border-radius: 50%;
        }
        &__text {
            @include body2;
            margin-bottom: 4px;
        }
        &__date {
            @include caption;
            color: var(--ping-Grey-grey-500);
        }
    }
    &-empty {
        @include subtitle1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
    }
}
