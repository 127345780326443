.additional-options {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
}

.additional-options {
    &.show {
        max-height: 300px;
    }
}

.dropdown-icon-up {
    transition: all 0.3s;
}

.dropdown-icon-down {
    @extend .dropdown-icon-up;
    transform: rotate(180deg);
}
