@import "styles/mixins";

/* ping-text-styles/text-label */
@mixin text-label {
    color: var(--ping-Grey-grey-800);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4; /* 142.857% */
    letter-spacing: 0.2px;
}

.text-label {
    @include text-label;
}

/* ping-text-styles/overline text */
@mixin overline-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

.overline-text {
    @include overline-text;
}

/* ping-text-styles/body1 */
@mixin body1 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.2px;
}

.body1 {
    @include body1;
}

/* ping-text-styles/body2 */
@mixin body2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: -0.2px;
}

.body2 {
    @include body2;
}

/* ping-text-styles/body3 */
@mixin body3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.body3 {
    @include body3;
}

/* ping-text-styles/body2-link */
@mixin body2-link {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.2px;
}

.body2-link {
    @include body2-link;
}

/* ping-text-styles/caption */
@mixin caption {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0.2px;
}

.caption {
    @include caption;
}

/* ping-text-styles/subtitle2 */
@mixin subtitle2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
}

.subtitle2 {
    @include subtitle2;
}

/* ping-text-styles/subtitle1 */
@mixin subtitle1 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    @include w($md) {
        @include subtitle2;
    }
}

.subtitle1 {
    @include subtitle1;
}

/* ping-text-styles/display-large */
@mixin display-large {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: -0.2px;
}

.display-large {
    @include display-large;
}

/* ping-text-styles/headings/h3 */
@mixin h3 {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25; /* 125% */
    letter-spacing: normal;
}

h3,
.h3 {
    @include h3;
}

/* ping-text-styles/headings/h2 */
@mixin h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.5px;
    @include w($md) {
        @include h3;
    }
}

h2,
.h2 {
    @include h2;
}

/* ping-text-styles/headings/h4 */
@mixin h4 {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3; /* 133.333% */
    letter-spacing: normal;
}

h4,
.h4 {
    @include h4;
}

/* ping-text-styles/headings/h5 */
@mixin h5 {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4; /* 140% */
}

h5,
.h5 {
    @include h5;
}

/* ping-text-styles/headings/h6 */
@mixin h6 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3;
}

h6,
.h6 {
    @include h6;
}

/* ping-text-styles/headings/h1 */
@mixin h1 {
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.15;
    letter-spacing: -1px;
    @include w($md) {
        @include h4;
    }
}

h1,
.h1 {
    @include h1;
}
