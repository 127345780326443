@import 'styles/mixins';

.statistic-bar {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
    &-item {
        border-radius: 8px;
        border: 1px solid var(--ping-Grey-grey-200);
        background-color: #fff;
        padding: 12px;
        flex: 1 0 0;
        display: flex;
        &__content {
            flex-grow: 1;
        }
        &__left-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 8px;
        }
        &__icon {
            width: 32px;
            height: 32px;
            border-radius: 4px;
            background-color: var(--ping-Brand-brand-50);
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__title {
            color: var(--ping-Grey-grey-500);
            vertical-align: middle;
            span {
                margin-right: 4px;
            }
            .tooltip-component {
                display: inline-flex;
                vertical-align: inherit;
            }
        }
        &__value {
            padding-top: 4px;
        }
    }
    @include w($md) {
        flex-direction: column;
    }
}
