.custom-date-picker {
    .MuiInputBase-input.MuiOutlinedInput-input {
        font-weight: 500;
        padding: 0 14px;
        height: 32px;
    }
    .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
        color: var(--ping-Grey-grey-900);
    }
}
