@import "styles/texts.scss";

.custom-chart {
    height: 270px;
    width: 100%;
}
.MuiPopper-root {
    .MuiChartsTooltip-root {
        thead {
            background: var(--ping-Grey-grey-100);
            .MuiTypography-root {
                @include caption;
                color: var(--ping-Grey-grey-800);
            }
        }
        tbody {
            .MuiTypography-root {
                @include caption;
                color: var(--ping-Grey-grey-700);
            }
        }
    }
}
