@import "styles/texts";

.custom-accordion {
    .MuiPaper-root {
        border-radius: 0;
        box-shadow: none;
    }
    .MuiButtonBase-root.MuiAccordionSummary-root {
        min-height: auto;
        padding: 0;
        justify-content: flex-start;
    }
    .MuiAccordionSummary-content {
        margin: 5px 0;
        flex-grow: 0;
        @include subtitle2;

        &.Mui-expanded {
            margin: 5px 0;
        }
    }
    .MuiAccordionDetails-root {
        padding: 0;
    }
}
