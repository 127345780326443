@import 'styles/texts';

.custom-text-input {
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
        height: 40px;
        background-color: #fff;
    }
    .MuiInputBase-input.MuiOutlinedInput-input {
        &::placeholder {
            color: var(--ping-Grey-grey-500);
            opacity: 1;
        }
    }
    .MuiFormHelperText-root.Mui-error {
        margin: 4px 0 0 0;
        @include body2;
    }
}

.small-input {
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
        height: 28px !important;
    }
}
