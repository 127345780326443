@import 'styles/texts.scss';

.status-cell {
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    height: 20px;
    display: inline-flex;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    @include caption;
    text-transform: capitalize;
}

.text-cell {
    color: var(--ping-Grey-grey-700);
    @include body2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    gap: 4px;
    &.capitalize {
        text-transform: capitalize;
    }
    & > div {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.service-cell {
    display: flex;
    align-items: center;
    gap: 8px;
    &__icon {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        svg {
            font-size: 36px;
            width: 1em;
            height: 1em;
        }
    }
    &__content {
    }
    &-title {
        @include subtitle2;
        color: var(--ping-Grey-grey-800);
    }
    &-info {
        display: flex;
        gap: 6px;
        align-items: center;
        color: var(--ping-Grey-grey-500);
        @include caption;
    }
}

.payment-cell {
    display: flex;
    align-items: center;
    &__icon {
        display: flex;
        align-items: center;
        margin-right: 8px;
    }
    &__value {
        display: flex;
        align-items: center;
        @include body2;
        gap: 4px;
    }
}

.card-dots {
    display: inline-flex;
    width: 32px;
    background-image: url('../../../icons/colored/card-dots.svg');
    height: 20px;
    background-repeat: no-repeat;
}

.linked-product-cell {
    display: flex;
    gap: 8px;
    svg {
        width: 32px;
    }
}

.log-chart-cell {
    width: 100%;
}
.usage {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--ping-Grey-grey-700);
    margin-bottom: 4px;
    @include body2;
    &__value {
    }
    &__limit {
    }
    &__divider {
        color: var(--ping-Grey-grey-400);
    }
}

.usage-no-limit {
    @extend .usage;
    margin-bottom: 0;
}

.progress-bar {
    height: 4px;
    width: 100%;
    border-radius: 2px;
    overflow: hidden;
    background-color: var(--ping-Grey-grey-200);
    &__value {
        border-radius: 2px;
        width: 50%;
        height: 100%;
        border-right: 1px solid #fff;
        &.orange {
            background-color: var(--ping-Orange-orange-400);
        }
        &.red {
            background-color: var(--ping-Red-red-400);
        }
        &.green {
            background-color: var(--ping-Green-green-400);
        }
    }
}

.password-cell {
    display: flex;
    align-items: center;
    @include body2;
    color: var(--ping-Grey-grey-700);
    width: 100%;
    position: relative;
    &__value {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 30px);
    }
    &__button {
        flex-shrink: 0;
        display: flex;
        margin-left: 8px;
    }
}

.list-cell {
    display: flex;
    align-items: center;

    &__main {
        @include body2;
        color: var(--ping-Grey-grey-700);
    }
    &__label {
        margin-left: 8px;
    }
}

.amount-cell {
    text-align: right;
    width: 100%;
    @include body2;

    &[data-positive='true'] {
        color: var(--ping-Green-green-800);
    }
    &[data-positive='false'] {
        color: var(--ping-Red-red-600);
    }
}

.copy-cell {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.usage__limit {
    & > span {
        font-size: 18px;
    }
}
