@import 'styles/mixins';

.dashboard-layout {
    .dashboard-container {
        display: flex;
        gap: 24px 48px;
    }
    .dashboard-content {
        width: 100%;
    }
    .dashboard-sidebar {
        flex: 1 0 220px;
    }
    .dashboard-top {
    }
    .dashboard-services {
        margin-bottom: 24px;
    }
    @include w($xl) {
        .dashboard-container {
            flex-direction: column;
        }
        .dashboard-content {
            width: 100%;
        }
        .dashboard-sidebar {
            flex: 1 1 auto;
            max-width: 100%;
        }
    }

    @include w($sm) {
        .dashboard-sidebar {
            flex-direction: column;
        }
    }
}
