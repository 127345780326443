.info-banner {
    border-radius: 8px;
    border: 1px solid var(--ping-Blue-blue-100);
    background: var(--ping-Blue-blue-50);
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    svg {
        flex-shrink: 0;
        margin-bottom: auto;
    }
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
        width: 100%;
    }
}
