@import 'styles/mixins';
@import 'styles/texts';

.product-box {
    &-header {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        margin-bottom: 24px;
        h4 {
            margin-bottom: 4px;
        }
        p {
            color: var(--ping-Grey-grey-500);
        }
        svg {
            width: 56px;
            flex-shrink: 0;
        }
    }
    &-prices {
    }
    .custom-slider {
        margin-bottom: 21px;
        @include w($sm) {
            .MuiSlider-markLabel {
                font-size: 12px;
            }
        }

        &.slider-with-gb-value {
            @include w(1440) {
                .MuiSlider-markLabel {
                    &[data-index='1'],
                    &[data-index='3'],
                    &[data-index='5'],
                    &[data-index='7'],
                    &[data-index='9'],
                    &[data-index='11'],
                    &[data-index='13'] {
                        // display: none;
                        margin-top: 16px;
                    }
                }
            }
        }
        &.slider-with-ip-value {
            @include w($xxl) {
                .MuiSlider-markLabel {
                    &[data-index="13"], // 50
                    &[data-index="17"] // 150
                    {
                        display: none;
                    }
                }
            }
            @include w($sm) {
                .MuiSlider-markLabel {
                    &[data-index="15"], // 100
                    &[data-index="22"], // 300
                    &[data-index="26"], // 500
                    &[data-index="28"], // 700
                    &[data-index="30"] // 900
                    {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}

.prices-tables {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.price-table {
    &[data-name='1 month'] {
        order: 1;
    }
    &[data-name='3 months'] {
        order: 2;
    }
    &[data-name='12 months'] {
        order: 3;
    }
    &__title {
        @include subtitle1;
        margin-bottom: 6px;
    }

    .MuiDataGrid-columnHeader,
    .MuiDataGrid-cell {
        &:not(:first-child) {
            padding: 0 4px !important;
        }
    }
}

.slider-toogle-button {
    color: var(--ping-Brand-brand-500) !important;
}

.bold-part {
    font-weight: bold;
    color: var(--ping-Grey-grey-900);
}
